'use client'

import { sortBy, uniqBy } from 'lodash'
import { useEffect, useState, type ComponentPropsWithoutRef } from 'react'
import { useInstantSearch } from 'react-instantsearch'
import slugify from '@sindresorhus/slugify'
import BlockRenderer from '@cms/renderers/block-renderer'
import { getContentfulSearchPromos } from '@lib/services/actions/contentful-search-promos'
import { type TypeSearchPromo } from '@lib/generated-types'

export interface LocationWaitTimesProps extends ComponentPropsWithoutRef<'div'> {
  searchPromoTag: string
}

const SearchPromos = ({ searchPromoTag }: LocationWaitTimesProps) => {
  const [promoMap, setPromoMap] = useState<{ [key: string]: TypeSearchPromo }>({})

  useEffect(() => {
    async function fetchPromos() {
      const promoMap = await getContentfulSearchPromos(searchPromoTag)
      setPromoMap(promoMap)
    }
    fetchPromos()
  }, [searchPromoTag])

  const { results } = useInstantSearch()

  // Active promos configured in Algolia
  const activePromos = uniqBy(results.userData, 'internalName') ?? []

  const hasAvailablePromos = Object.keys(promoMap).length > 0

  if (!hasAvailablePromos || activePromos.length < 1) return null

  // availablePromos represent all tagged promos from Contentful
  const sortedAvailablePromos = hasAvailablePromos
    ? sortBy(promoMap, (availablePromo) =>
        availablePromo?.fields?.blocks?.[0]?.fields?.variant === 'card' ? 0 : 1
      )
    : []

  // Select the promos that both available (Contentful) and active (Algolia)
  const filteredPromos = sortedAvailablePromos.filter((promo) => {
    const promoInternalName = promo?.fields?.internalName ?? ''
    return activePromos.some((activePromo) => activePromo.internalName === promoInternalName)
  })

  return filteredPromos.map((data) => (
    <div key={slugify(data.fields.internalName)}>
      {data?.fields?.blocks?.map((block) => {
        return <BlockRenderer key={block.sys.id} block={block} />
      })}
    </div>
  ))
}

export default SearchPromos
